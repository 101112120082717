<template>
	<div>
		<el-dialog title="" width="600px" class="icon-dialog" :visible.sync="show" @open="open" :before-close="closeForm" append-to-body>
			<div class="main_select ">重置密码</div>
			<el-form class="pt-20 pb-20" :size="size" ref="form" :model="form" :rules="rules" label-width="80px">
				<el-row >
					<el-col :span="24">
						<el-form-item label="原密码" prop="originalLoginPass">
							<el-input  show-password autoComplete="off" v-model="form.originalLoginPass"  clearable placeholder="请输入原始密码"/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row >
					<el-col :span="24">
						<el-form-item label="新密码" prop="newLoginPass">
							<el-input  show-password autoComplete="off" v-model="form.newLoginPass"  clearable placeholder="请输入新密码"/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row >
					<el-col :span="24">
						<el-form-item label="确认密码" prop="newLoginPassAgain">
							<el-input  show-password autoComplete="off" v-model="form.newLoginPassAgain"  clearable placeholder="请输入确认密码"/>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button style="background-color: #094693;color: #fff;" :size="size" :loading="loading" type="primary" @click="submit" >
					<span v-if="!loading">确 定</span>
					<span v-else>提 交 中...</span>
				</el-button>
				<el-button :size="size" @click="closeForm">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import jsSHA from "jssha"
import { changePass  } from '@/api/admin/base'
export default {
	name:'adminuserresetPwd',
	components: {
	},
	props: {
		show: {
			type: Boolean,
			default: false
		},
		size: {
			type: String,
			default: 'small'
		},
		info: {
			type: Object,
		},
	},
	data(){
		var validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'))
			} else if (value !== this.form.passWord) {
				callback(new Error('两次输入密码不一致!'))
			} else {
				callback()
			}
		}
		return {
			form: {
				newLoginPass:'',
				newLoginPassAgain	:'',
				originalLoginPass:'',
				
			},
		    form1:{},
			loading:false,
			rules: {
				originalLoginPass:[{required: true, message: '请输入原始密码', trigger: 'blur'}],
				newLoginPass:[{required: true, message: '请输入新密码', trigger: 'blur'}],
				newLoginPassAgain:[{required: true,message: '请再次输入新密码', trigger: 'blur'}],
			}
		}
	},
	
	methods: {
		open(){},
		submit(){
			
			this.$refs['form'].validate(valid => {
				if(valid) {
					this.form1.originalLoginPass = this.getPassword1(this.form.originalLoginPass);
					this.form1.newLoginPass = this.getPassword1(this.form.newLoginPass)
					this.form1.newLoginPassAgain = this.getPassword1(this.form.newLoginPassAgain);
					if(this.form1.newLoginPass!=this.form1.newLoginPassAgain){
						this.$message({message: '密码不一致', type: 'warning'});
						return false
					}
					this.loading = true;
					changePass(this.form1).then(res => {
						this.$message({message: '操作成功', type: 'success'})
						localStorage.clear();
						location.href = '/';
						// this.$emit('refesh_list')
						// this.closeForm()
					}).catch(()=>{
						this.loading = false
					})
				}
			})
		},
		//  密码加密--sha512
		getPassword(password){
		  const shaObj = new jsSHA('SHA-512','TEXT',{encoding:"UTF8"})
		  shaObj.update(password);
		  var timestamp=new Date().getTime();
		  const pas = shaObj.getHash("HEX")+timestamp;
		  this.form1.timestamp = timestamp;
		  const shaObj1 = new jsSHA('SHA-512','TEXT',{encoding:"UTF8"})
		  shaObj1.update(pas);
		  return shaObj1.getHash("HEX");
		},
		getPassword1(password){
		  const shaObj = new jsSHA('SHA-512','TEXT',{encoding:"UTF8"})
		  shaObj.update(password);
		  return shaObj.getHash("HEX")
		},
		closeForm(){
			this.$emit('update:show', false)
			this.loading = false
			if (this.$refs['form']!==undefined) {
				this.$refs['form'].resetFields()
			}
		},
	}
}
</script>
<style  lang="scss">
@import '@/assets/scss/common.scss'
</style>
